<template>
<v-container>
<v-row
      class="mt-3"
      align="center"
      justify="center"
      >
      <v-col         
        class="text-center"
        cols="12"
  
        <h1 class="display-1 font-weight-bold mb-3">
          AKIVA SERVICES
        </h1>
      </v-col>        
</v-row>
<v-col         
        class="text-center"
        cols="12"
        ><v-icon
         x-large
         color="blue darken-2"
        >mdi-minus</v-icon>
        <p class="subheading font-weight-regular">
          Specializing in blending the worlds of business and technology strategy, Akiva works with larger organizations to maximize productivity and ensure positive business outcomes.
         
        
        </p>
        </v-col>
<v-row>
    <v-container>
      <v-row 
      class="mx-3">
        <v-col
          v-for="(feature, i) in features"
          :key="i"
          cols="12"
          md="6"
        >
        <v-avatar 
         class="ma-1"
        size=75 
        color="grey lighten-3">
        <v-icon v-if="feature.icon.startsWith('mdi-')" x-large>
          {{ feature.icon }}
        </v-icon>
        <img v-else :src="feature.icon" alt="Feature icon" width="48" height="48">     
        </v-avatar>
        
          <v-avatar-card
            name="feature.title"
            v-bind="features"
            align="left"
            horizontal
            class="font-weight-bold"
          >
          {{feature.title}} <br>
           <p class="font-weight-regular">{{feature.desc}} 
            </p >
          </v-avatar-card>
        </v-col>
      </v-row>
    </v-container>
</v-row>
</v-container>
</template>
<script>
  export default {
    name: 'AkivaServices',
   data: () => ({
      features: [
        {
          title: 'Workday Advisory',
        //  icon: 'https://img.icons8.com/ios11/200/737373/workday.png',
          icon:  require('@/assets/workday_grey.png'),
          desc: 'Workday is a powerful enterprise platform that streamlines financial and human capital management, but maximizing its value requires a strategic approach. Our Workday Advisory services help organizations optimize their Workday implementation with a focus on financial processes, ensuring alignment with business objectives. We provide expert guidance on system configuration, reporting, and integrations to enhance operational efficiency. By leveraging best practices and deep domain expertise, we help organizations improve financial visibility, compliance, and decision-making, enabling them to drive sustainable growth and operational excellence.',
        },
        {
          title: 'Enterprise Architecture',
          icon: 'mdi-math-compass',
          desc: 'Enterprise Architecture is the strategic design and governance of an organization’s technology landscape, ensuring resilience, flexibility, and agility in delivering business value. By aligning IT systems with evolving business needs, it enables organizations to adapt to change, optimize operations, and drive innovation. A well-structured enterprise architecture fosters seamless integration, scalability, and efficiency, empowering businesses to navigate complexity and stay ahead in a rapidly evolving industry.',
        },
        {
          title: 'Data Strategy',
          icon: 'mdi-database',
          desc: 'Data has become a critical asset for organizations in today\'s digital landscape. Having a comprehensive data strategy is essential for leveraging this asset effectively. This strategy starts by gaining a deep understanding of an organization\'s data entities, systems of record, and how they impact the overall business. By identifying key data points and understanding their relationships, organizations can make informed decisions that drive business growth and innovation.',
        },       
        {
          color: 'primary',
          dark: true,
          title: 'CI/CD',
          icon: 'mdi-play-box-multiple',
          desc: 'Continuous Integration and Continuous Deployment plays a foundational role in a modern IT organizations ability to deliver solutions quickly and with quality.  When achieved, the ‘bottle neck’ of business solutions shifts from IT delivery timeframes to becomes the ability of an organizations to think of creative solutions. ',
        },
        {
          title: 'Cloud Native',
          icon: 'mdi-cloud',
         // icon: 'https://img.icons8.com/ios11/200/737373/workday.png',
          desc: 'Beyond the buzz word nature of ‘cloud native’ solutions is the understanding of the value, flexibility and cost advantage of embracing a modern IT infrastructure.  The ‘pay-as-you-go’ and ‘only-for-what-you-use’ models allows all organizations to harness technology capabilities previously left to a small handful of the mega tech companies.',
        },

        {
          title: 'Team Building',
          icon: 'mdi-account-group',
          desc: 'Leveraging consulting services is great for point in time projects.  However, depending on several factors, the ongoing maintenance and day to day operations of a solution is often better served by inhouse resources.  Although this goes against the ‘consultants come to study a problem, then stay to become part of it’ approach, we believe that we do better when our clients do better.  This entails ensuring that a client has the appropriate in-house talent for a solution to continue to deliver value long past the completion of a project.',
        },
      ],
    }),
  }
</script>