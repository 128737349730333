<template>
    <v-parallax
    dark
    height="880"
    :src="require('../assets/rainforest.jpg')"
  >
    <v-row
      align="center"
      justify="center"
    >
    <v-col
        class="text-left"
        cols="11"
        >
      <v-card
          elevation="6"
          >  
            <v-row
      
      justify="center"
    >
          <v-col
            class="text-left"
            cols="12"
            md="12"
          >
            <v-card
              elevation="0"
              >  
              <v-card-text>
                <h1 class="display-2 text--primary">Contact Us</h1><br>
                <v-spacer></v-spacer>
                <div class="text-h6">
                Please feel free to reach out to us by phone or email<br><h1> </h1>
                <v-divider class="ma-2"></v-divider>
                  <v-icon
                   class='ma-2'
                    large>
                      mdi-phone
                  </v-icon> 
                 <a href="tel:(720) 439-9706‬">(720) 439-9706‬</a>
                  <h1></h1>
                  <v-icon
                    href="mailto:sales@akivatech.com"
                    target="_blank"
                   class='ma-2'
                    large>
                      mdi-email
                  </v-icon> 
                   <a href="mailto:sales@akivatech.com‬">sales@akivatech.com‬</a>
                   <h1></h1>

                </div>
              </v-card-text>
            </v-card>
        </v-col>
        <!-- <v-col
          class="text-left"
          cols="12"
          md="6"
        >
          <v-card
              elevation="0"
              >  
              <v-card-text>
                <h1 class="display-2 text--primary">Send a Message</h1><br>
                <v-spacer></v-spacer>
                <v-text-field
                label="Name"  
                hint="Please add the name you would like to be addressed by"
                ref="name"
                v-model="name"
                ></v-text-field>
                <v-text-field
                label="Email"  
                hint="Please add contact email to repond to"
                ref="email"
                v-model="email"
                ></v-text-field>
                <v-text-field
                label="Phone"  
                hint="Please include a call back number"
                ref="phone"
                v-model="phone"
                ></v-text-field>
                <v-textarea
                label="Message"  
                hint="Nature of the contact"
                ref="message"
                v-model="message"
                ></v-textarea>
                <v-btn
                v-on:click="sendmessage"
                 elevation="2"
                >Send</v-btn>
              </v-card-text>
          </v-card>
        </v-col> -->
         </v-row>  
      </v-card>
      </v-col>
    </v-row>  
    <v-row>
    </v-row>
  </v-parallax>
</template>
<script>
import dal from '../dal/dal.js'
export default {
    name: 'ContactUs',

      data: () => ({
      errorMessages: '',
      name: null,
      email: null,
      phone: null,
      message: null,
  
    }),
    methods: {
      sendmessage() {
        console.log('send message comp')
        dal.sendsms(this.name,this.email,this.phone,this.message)
      }
    }
}
</script>